<template>
  <ul class="kt-menu__nav">
    <template v-for="(menu, i) in menuItems">
      <KTMenuItem :key="i" v-bind:menu="menu"></KTMenuItem>
    </template>
  </ul>
</template>

<script>
import KTMenuItem from "@/views/theme/header/MenuItem.vue";
// import menuConfig from "@/common/config/menu.config.json";

export default {
  name: "KTHeaderMenu",
  components: {
    KTMenuItem,
  },
  data() {
    return {
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      menuItems: [],
    };
  },
  methods: {
    updateView() {
      this.menuItems = this.getMenuItems(this.$store.getters.requestInfo);
    },
    getMenuItems: (requestInfo) => {
      const getMenuTitleSliceText = function (title) {
        if (title.length <= 30) {
          return title;
        } else {
          return title.slice(0, 28) + "...";
        }
      };
      const getGenderText = function (genderCode) {
        if (genderCode === "1" || genderCode === 1) {
          return "남";
        } else if (genderCode === "2" || genderCode === 2) {
          return "여";
        } else {
          return "-";
        }
      };
      const items = [];
      const requestRoot = { title: "요청정보", root: true, toggle: "click" };
      const requestSubMenu = [];
      const requestClient = {
        title: getMenuTitleSliceText(
          "고객사 : " + requestInfo.req_info.req_client_code
        ),
      };
      const requestId = {
        title: getMenuTitleSliceText("요청ID : " + requestInfo.req_info.req_id),
      };
      const requestUserId = {
        title: getMenuTitleSliceText(
          "주민번호 : " + requestInfo.user_info.registration_no
        ),
      };
      requestSubMenu.push(requestClient);
      requestSubMenu.push(requestId);
      requestSubMenu.push(requestUserId);
      requestRoot.submenu = requestSubMenu;
      items.push(requestRoot);
      const userRoot = { title: "고객정보", root: true, toggle: "click" };
      const userSubMenu = [];
      const userName = {
        title: getMenuTitleSliceText("성명 : " + requestInfo.user_info.name),
      };
      const userGender = {
        title: "성별 : " + getGenderText(requestInfo.user_info.gender),
      };
      const userBirth = {
        title: getMenuTitleSliceText(
          "생년월일 : " + requestInfo.user_info.birth_date
        ),
      };
      const userWorkplace = {
        title: getMenuTitleSliceText(
          "직장 : " + requestInfo.user_info.workplace
        ),
      };
      let userEtcInfo = "";
      if (requestInfo.matching_info) {
        requestInfo.matching_info.forEach((item) => {
          userEtcInfo += item.matching_keyword + ", ";
        });
      }
      const userAddInfo = {
        title: getMenuTitleSliceText("추가정보 : " + userEtcInfo),
      };
      const userJobType = {
        title: "직업구분 : ",
        isJobTypeSelectMenu: true,
        selectedType: "직장인",
      };
      userSubMenu.push(userJobType);
      userSubMenu.push(userName);
      userSubMenu.push(userGender);
      userSubMenu.push(userBirth);
      userSubMenu.push(userWorkplace);
      userSubMenu.push(userAddInfo);
      userRoot.submenu = userSubMenu;
      items.push(userRoot);
      return items;
    },
  },
  mounted() {
    this.menuItems = this.getMenuItems(this.$store.getters.requestInfo);
  },
};
</script>
