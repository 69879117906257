<template>
  <ul class="kt-menu__nav">
    <div
      id="kt-menu__file"
      style="padding-left: 10px; padding-right: 10px; padding-bottom: 20px;"
    >
      <v-select
        v-model="selectedSampleType"
        :items="samples"
        color="#ffffff"
        dark
        dense
        hide-details
        outlined
      ></v-select>
    </div>
    <!--    <div id="kt-menu__file" style="padding-left: 10px; padding-right: 10px;">-->
    <!--      <v-file-input-->
    <!--        v-model="files"-->
    <!--        color="white accent-4"-->
    <!--        label="대상자 파일"-->
    <!--        @change="previewFiles"-->
    <!--        prepend-icon="mdi-briefcase-upload-outline"-->
    <!--        outlined-->
    <!--        dark-->
    <!--        hide-details-->
    <!--        accept=".json"-->
    <!--        :show-size="1000"-->
    <!--      >-->
    <!--        <template v-slot:label="{ text }">-->
    <!--          <span style="color: white;">{{ text }}</span>-->
    <!--        </template>-->
    <!--        <template v-slot:selection="{ index, text }">-->
    <!--          <v-chip v-if="index < 2" color="seize-black accent-4" label small>-->
    <!--            {{ text }}-->
    <!--          </v-chip>-->
    <!--        </template>-->
    <!--      </v-file-input>-->
    <!--    </div>-->
    <template v-for="(menu, i) in menuItems">
      <KTMenuItem v-if="!menu.section" :key="i" :menu="menu"></KTMenuItem>
      <KTMenuSection v-if="menu.section" :key="i" :menu="menu"></KTMenuSection>
    </template>
  </ul>
</template>

<script>
import KTMenuItem from "@/views/theme/aside/MenuItem.vue";
import KTMenuSection from "@/views/theme/aside/MenuSection.vue";
import menuConfig from "@/common/config/menu.config.json";
import constants from "@/common/utils/constants";

export default {
  name: "KTAsideMenu",
  data() {
    return {
      files: null,
      samples: constants.SAMPLE_TYPE_LIST,
    };
  },
  components: {
    KTMenuItem,
    KTMenuSection,
  },
  methods: {
    previewFiles(files) {
      this.$store.commit("setupFromFile", files);
      this.$emit("update-view");
    },
  },
  computed: {
    selectedSampleType: {
      get: function () {
        return this.$store.getters.selectedSampleType;
      },
      set: function (value) {
        this.$store.commit("selectSampleType", value);
        this.$emit("update-view");
      },
    },
    menuItems: () => {
      return menuConfig.aside.items;
    },
  },
};
</script>
